import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BookingStep, BookingStepService } from '../../services/booking-step/booking-step.service';
import { CompanyBookingComponent } from '../company-booking/company-booking.component';
import { DraftBookingComponent } from '../draft-booking/draft-booking.component';
import { ProvinceBookingComponent } from '../province-booking/province-booking.component';
import { BillingTypeBookingComponent } from '../billing-type-booking/billing-type-booking.component';
import { BillingTypeService } from '../../services/billing-type/billing-type.service';
import { BillingRegionService } from '../../services/billing-region/billing-region.service';

export interface ConfirmBookingQueryParams {
  draftAppointmentId: string | undefined;
}

@Component({
  selector: 'insig-booking-your-details',
  templateUrl: './your-details.component.html',
  styleUrls: ['./your-details.component.scss'],
})
export class YourDetailsComponent {
  private route = inject(ActivatedRoute);
  private bookingStepService = inject(BookingStepService);
  private billingTypeService = inject(BillingTypeService);
  private billingRegionService = inject(BillingRegionService);

  handleLoggedIn(): void {
    this.navigateToConfirmBookingPage();
  }

  navigateToConfirmBookingPage(): void {
    try {
      const draftBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(this.route, [DraftBookingComponent]);
      const draftAppointmentId = draftBookingRoute.snapshot.params.draftAppointmentId;
      const companyBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(this.route, [CompanyBookingComponent]);
      this.bookingStepService.jumpToStep(BookingStep.CONFIRM_BOOKING, {
        navigationExtras: { relativeTo: companyBookingRoute, replaceUrl: true },
        pathParams: { draftAppointmentId },
      });
    } catch (_error) {
      const companyBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(this.route, [CompanyBookingComponent]);
      const provinceBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(this.route, [ProvinceBookingComponent]);
      const provinceAbbreviation = provinceBookingRoute.snapshot.params.provinceAbbreviation;
      const billingTypeBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(this.route, [BillingTypeBookingComponent]);
      const billingType = this.billingTypeService.parseBillingType(billingTypeBookingRoute.snapshot.params.billingType);

      const region = this.billingRegionService.getBillingRegion(provinceAbbreviation, billingType);

      this.bookingStepService.jumpToStep(BookingStep.CHOOSE_DOCTOR, {
        navigationExtras: { relativeTo: companyBookingRoute, replaceUrl: true },
        pathParams: { region },
      });
    }
  }
}
