import { Pipe, PipeTransform, inject } from '@angular/core';
import { DateAndTimeService } from '@insig-health/services/date-and-time/date-and-time.service';

@Pipe({
  standalone: true,
  name: 'addMinutesToDate',
})
export class AddMinutesToDatePipe implements PipeTransform {
  private dateAndTimeService = inject(DateAndTimeService);

  transform(date: Date, minutes: number): Date {
    return this.dateAndTimeService.addMinutesToDate(date, minutes);
  }
}
