import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { JAVA_BACKEND_ENDPOINT } from '@insig-health/config/config';
import { GcpIpAuthService } from '@insig-health/gcp-ip/gcp-ip-auth.service';

import { from, lastValueFrom, Observable } from 'rxjs';

@Injectable()
export class InsigBookingSpringAuthorizationInterceptor implements HttpInterceptor {
  private gcpIpAuthService = inject(GcpIpAuthService);

  /**
   * Any requests to the java backend should send the Authorization header with the
   * firebase Bearer token, if the user is logged in.
   */
  intercept<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    return from(this.handle(request, next));
  }

  async handle<T>(request: HttpRequest<T>, next: HttpHandler): Promise<HttpEvent<T>> {
    const requestUrl = new URL(request.url);
    const javaBackendUrl = new URL(JAVA_BACKEND_ENDPOINT);

    if (requestUrl.host !== javaBackendUrl.host) {
      // Do not automatically add the token
      return lastValueFrom(next.handle(request));
    }

    const user = this.gcpIpAuthService.getCurrentUser();
    const isLoggedIn = !!user;
    const isRequestingCustomToken = requestUrl.pathname === '/auth/firebase-custom-token';

    if (isRequestingCustomToken) {
      if (isLoggedIn) {
        const requestWithIdToken = await this.gcpIpAuthService.getRequestWithIdToken(request, user);
        return lastValueFrom(next.handle(requestWithIdToken));
      } else {
        // Try getting custom token with a cookie
        request = request.clone({ withCredentials: true });
        return lastValueFrom(next.handle(request));
      }
    } else {
      if (isLoggedIn) {
        const requestWithIdToken = await this.gcpIpAuthService.getRequestWithIdToken(request, user);
        return lastValueFrom(next.handle(requestWithIdToken));
      } else {
        return lastValueFrom(next.handle(request));
      }
    }
  }
}
