import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { DateAndTimeService } from '@insig-health/services/date-and-time/date-and-time.service';

@Component({
  selector: 'insig-booking-appointment-slot-week-select',
  templateUrl: './appointment-slot-week-select.component.html',
  styleUrls: ['./appointment-slot-week-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentSlotWeekSelectComponent implements OnInit {
  static readonly CURRENT_WEEK_TEXT = 'This Week';
  static readonly NUMBER_OF_DAYS_TO_SHOW_ON_CALENDAR = 7;

  private dateAndTimeService = inject(DateAndTimeService);
  private changeDetector = inject(ChangeDetectorRef);

  @Input() initialStartDate = new Date();
  @Input() earliestAvailableTime: Date | undefined;
  @Output() startDateChange = new EventEmitter<Date>();

  public _weekIndicatorText = AppointmentSlotWeekSelectComponent.CURRENT_WEEK_TEXT;
  set weekIndicatorText(value: string) {
    this.loading = true;
    this.changeDetector.detectChanges();
    this._weekIndicatorText = value;
    this.loading = false;
    this.changeDetector.detectChanges();
  }
  public startDate = new Date(this.initialStartDate);
  public loading = false;

  ngOnInit(): void {
    this.startDate = new Date(this.initialStartDate);
    this.weekIndicatorText = this.getWeekIndicatorText(this.startDate);
  }

  isPreviousWeekButtonDisabled(currentCalendarStartDate: Date | undefined, earliestAvailableTime: Date | undefined): boolean {
    if (!currentCalendarStartDate || !earliestAvailableTime) {
      return false;
    } else {
      const timeZone = this.dateAndTimeService.getLocalTimeZone();
      return this.dateAndTimeService.areTwoDatesTheSameDay(currentCalendarStartDate, earliestAvailableTime, timeZone);
    }
  }

  handlePreviousWeekButtonClick(): void {
    this.startDate = this.dateAndTimeService.minusWeekToDate(this.startDate);
    this.weekIndicatorText = this.getWeekIndicatorText(this.startDate);
    this.startDateChange.emit(this.startDate);
  }

  handleNextWeekButtonClick(): void {
    this.startDate = this.dateAndTimeService.addWeekToDate(this.startDate);
    this.weekIndicatorText = this.getWeekIndicatorText(this.startDate);
    this.startDateChange.emit(this.startDate);
  }

  getWeekIndicatorText(selectedDate: Date | undefined): string {
    if (!selectedDate) {
      return '';
    }

    const timeZone = this.dateAndTimeService.getLocalTimeZone();

    if (this.dateAndTimeService.areTwoDatesInTheSameWeek(selectedDate, new Date(), timeZone)) {
      return AppointmentSlotWeekSelectComponent.CURRENT_WEEK_TEXT;
    } else {
      return this.getWeekRangeText(selectedDate);
    }
  }

  getWeekRangeText(selectedDate: Date): string {
    const startMonth = this.dateAndTimeService.getMonthShortformName(selectedDate);
    const firstDate = this.dateAndTimeService.getDate(selectedDate);

    const secondDateObject = this.dateAndTimeService.addDaysToDate(selectedDate, AppointmentSlotWeekSelectComponent.NUMBER_OF_DAYS_TO_SHOW_ON_CALENDAR - 1);
    const secondDate = this.dateAndTimeService.getDate(secondDateObject);
    const endMonth = this.dateAndTimeService.getMonthShortformName(secondDateObject);

    return `${startMonth} ${firstDate} - ${endMonth} ${secondDate}`;
  }
}
