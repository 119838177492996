import { Component, HostListener, inject } from '@angular/core';
import { INSIG_BOOKING_THEME, PRODUCTION } from '@insig-health/config/config';
import { ThemeService } from '../services/theme/theme.service';

@Component({
  selector: 'insig-booking-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  private themeService = inject(ThemeService);
  public readonly PRODUCTION = PRODUCTION;

  constructor() {
    this.themeService.setCurrentTheme(INSIG_BOOKING_THEME);
  }

  @HostListener('window:pageshow', ['$event'])
  handlePageshow(event: PageTransitionEvent): void {
    if (event.persisted) {
      window.location.reload();
    }
  }
}
