import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, inject } from '@angular/core';
import { InsigPopupMenuVisibleState } from '@insig-health/components/lib/insig-popup-menu/insig-popup-menu.component';
import { TranslateService } from '@insig-health/services/translate/translate.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'insig-booking-language-dropdown',
  templateUrl: './language-dropdown.component.html',
  styleUrls: ['./language-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class LanguageDropdownComponent implements OnInit, OnDestroy {
  public translateService = inject(TranslateService);
  public popupVisibleState = InsigPopupMenuVisibleState.CLOSED;

  public languages: string[] = [];
  public currentLanguage: string;

  private languageSubscription: Subscription | undefined;

  constructor(
  ) {
    this.currentLanguage = this.translateService.language;
  }

  async ngOnInit(): Promise<void> {
    this.languageSubscription = this.translateService.getCurrentLanguage().subscribe((currentLanguage) => {
      this.currentLanguage = currentLanguage;
    });
    this.languages = await this.translateService.getLanguageList();
  }

  ngOnDestroy(): void {
    this.languageSubscription?.unsubscribe();
  }

  handleLanguageClicked(event: Event): void {
    this.popupVisibleState = InsigPopupMenuVisibleState.CLOSED;

    const selectedLanguage = (event.target as HTMLInputElement).value;
    this.translateService.changeLanguage(selectedLanguage);
  }
}
