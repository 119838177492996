import { Component, OnInit, inject } from '@angular/core';
import { LocationService, Region } from '@insig-health/services/location/location.service';
import { ProvinceService } from '@insig-health/services/province/province.service';
import { BillingType } from '../../services/billing-type/billing-type.service';
import { BookingStep, BookingStepService } from '../../services/booking-step/booking-step.service';
import { CompanyBookingComponent } from '../company-booking/company-booking.component';
import { ActivatedRoute } from '@angular/router';
import { BillingRegionService, RegionType } from '../../services/billing-region/billing-region.service';

@Component({
  selector: 'insig-booking-default-province-redirect',
  templateUrl: './default-province-redirect.component.html',
})
export class DefaultProvinceRedirectComponent implements OnInit {
  private locationService = inject(LocationService);
  private provinceService = inject(ProvinceService);
  private bookingStepService = inject(BookingStepService);
  private billingRegionService = inject(BillingRegionService);
  private route = inject(ActivatedRoute);

  async ngOnInit(): Promise<void> {
    const region = await this.locationService.getRegion().catch(() => ({
      countryAbbreviation: 'CA',
      regionAbbreviation: 'ON',
      regionName: 'Ontario',
    } as Region));

    const companyBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(this.route, [CompanyBookingComponent]);

    const billingRegion = this.billingRegionService.getBillingRegion(region.regionAbbreviation, BillingType.PRIVATE);
    if (billingRegion.type === RegionType.PROVINCE) {
      if (this.provinceService.isProvinceAvailableForPublicAppointments(billingRegion.provinceAbbreviation)) {
        billingRegion.billingType = BillingType.PUBLIC;
      }
    }

    this.bookingStepService.jumpToStep(BookingStep.CHOOSE_DOCTOR, {
      navigationExtras: {
        relativeTo: companyBookingRoute,
      },
      pathParams: { region: billingRegion },
    });
  }
}
