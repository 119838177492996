import { Component, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';

import { ActivatedRoute, NavigationEnd, NavigationExtras, Params, Router } from '@angular/router';
import { AggregationName, Appointment, DoctorFacetSearchData, DoctorSearchData } from '@insig-health/services/doctor/doctor.service';
import { AppointmentServiceMedium } from '@insig-health/api/doctor-booking-flow-api-v1';
import { BookingStep, BookingStepService } from '../../services/booking-step/booking-step.service';
import { Observable, ReplaySubject, Subject, Subscription, firstValueFrom, timer } from 'rxjs';
import { DoctorSearchService } from '../../services/doctor-search/doctor-search.service';
import { filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { CompanyBookingComponent } from '../company-booking/company-booking.component';
import { ProvinceBookingComponent } from '../province-booking/province-booking.component';
import { InternationalBookingComponent } from '../international-booking/international-booking.component';
import { InsigExpansionPanelState } from '@insig-health/components/lib/insig-expansion-panel/insig-expansion-panel.component';
import { Province, ProvinceService } from '@insig-health/services/province/province.service';
import { BillingType, BillingTypeService } from '../../services/billing-type/billing-type.service';
import { BillingTypeBookingComponent } from '../billing-type-booking/billing-type-booking.component';
import { PROVINCE_SELECTION_DIALOG_CONFIG, ProvinceSelectionDialogComponent, ProvinceSelectionDialogOptions } from './components/province-selection-dialog/province-selection-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AppointmentReservationService, DraftOrigin } from '../../services/appointment-reservation/appointment-reservation.service';
import { AppointmentMediumService } from '../../services/appointment-medium/appointment-medium.service';
import { BillingNumber } from '../../services/billing-number/billing-number.service';
import { LocationService, Region } from '@insig-health/services/location/location.service';
import { DateAndTimeService } from '@insig-health/services/date-and-time/date-and-time.service';
import { MILLISECONDS_PER_SECOND } from '@insig-health/services/date-and-time/date-and-time.constants';
import { MatSnackBar } from '@angular/material/snack-bar';
import { INSIG_BOOKING_SITE, SNACK_BAR_AUTO_DISMISS_MILLISECONDS } from '@insig-health/config/config';
import { BillingRegionService } from '../../services/billing-region/billing-region.service';
import { ThemeService } from '../../services/theme/theme.service';
import { QuickBookComponent } from './components/quick-book/quick-book.component';
import { SearchComponent } from '../search/search.component';

export interface DoctorSearchFilters {
  appointmentType?: string;
  doctorSearchQuery?: string;
  province: Province;
  doctorId?: string;
  billingType: BillingType;
  billingNumber?: BillingNumber;
}

export interface DoctorSearchFilterQueryParams {
  doctorId?: string | null;
  billingNumber?: BillingNumber | null;
  appointmentType?: string | null;
  billingGroupSelected?: boolean;
}

export interface ChooseDoctorRouteParams {
  companyId: string;
  province: Province;
  billingType: BillingType;
  doctorId?: string;
  appointmentType?: string;
  billingNumber?: BillingNumber;
  doNotShowBillingAndProvinceDialog?: boolean;
  billingGroupSelected?: boolean;
}

@Component({
  selector: 'insig-booking-choose-doctor',
  templateUrl: './choose-doctor.component.html',
  styleUrls: ['./choose-doctor.component.scss'],
})
export class ChooseDoctorComponent implements OnDestroy, OnInit {
  static readonly DO_NOT_SHOW_BILLING_AND_PROVINCE_DIALOG_QUERY_PARAM_KEY = 'doNotShowBillingAndProvinceDialog';
  static readonly DOCTORS_AND_FACETS_REFRESH_INTERVAL_DURATION = MILLISECONDS_PER_SECOND * 60;
  static readonly QUICK_BOOK_ERROR_MESSAGE = 'Sorry, this time slot is no longer available. We have refreshed the results.';

  private activatedRoute = inject(ActivatedRoute);
  private appointmentMediumService = inject(AppointmentMediumService);
  private appointmentReservationService = inject(AppointmentReservationService);
  private billingTypeService = inject(BillingTypeService);
  private billingRegionService = inject(BillingRegionService);
  private bookingStepService = inject(BookingStepService);
  private dateAndTimeService = inject(DateAndTimeService);
  private doctorSearchService = inject(DoctorSearchService);
  private dialog = inject(MatDialog);
  private locationService = inject(LocationService);
  private provinceService = inject(ProvinceService);
  private themeService = inject(ThemeService);
  private router = inject(Router);
  private snackBar = inject(MatSnackBar);

  @ViewChild('searchComponent') private searchComponent: SearchComponent | undefined;

  public BillingType = BillingType;

  public doctorSearchQuery: string | undefined = undefined;
  public doctorsAndFacets$: Observable<DoctorFacetSearchData>;

  public appointmentTypes: string[] = [];
  public appointmentTypes$: Observable<string[]>;

  public chooseDoctorRouteParams: ChooseDoctorRouteParams;

  public AppointmentServiceMedium = AppointmentServiceMedium;
  public InsigExpansionPanelState = InsigExpansionPanelState;

  public loadingDoctors = true;
  public currentDoctorSearchFilters = new ReplaySubject<DoctorSearchFilters>(1);

  public navigationEndSubscription: Subscription;

  constructor(
  ) {
    this.chooseDoctorRouteParams = this.getChooseDoctorRouteParamsFromActivatedRoute(this.activatedRoute);
    this.currentDoctorSearchFilters.next(this.getDoctorSearchFiltersFromChooseDoctorRouteParams(this.chooseDoctorRouteParams));

    this.doctorsAndFacets$ = this.getDoctorAndFacetDataObservable(this.chooseDoctorRouteParams.companyId, this.currentDoctorSearchFilters);

    this.appointmentTypes$ = this.doctorsAndFacets$.pipe(map((doctorAndFacets) => this.getAppointmentTypesFromDoctorFacetSearchData(doctorAndFacets)));

    this.navigationEndSubscription = this.router.events
      .pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd),
        map((_event: NavigationEnd) => this.getChooseDoctorRouteParamsFromActivatedRoute(this.activatedRoute)),
      )
      .subscribe((updatedChooseDoctorRouteParams) => this.handleChooseDoctorRouteParamsChange(updatedChooseDoctorRouteParams));
  }

  async ngOnInit(): Promise<void> {
    const isRegionSelectorEnabledByQueryParams = !this.chooseDoctorRouteParams.doNotShowBillingAndProvinceDialog;
    const isRegionSelectorEnabledByTheme = this.themeService.getCurrentThemeConfig().isRegionSelectorVisible;

    if (isRegionSelectorEnabledByQueryParams && isRegionSelectorEnabledByTheme) {
      const region = await this.locationService.getRegion().catch(() => ({
        countryAbbreviation: 'CA',
        regionAbbreviation: 'ON',
        regionName: 'Ontario',
      } as Region));

      this.openProvinceSelectionDialog(region);
    }
  }

  ngOnDestroy(): void {
    this.navigationEndSubscription.unsubscribe();
  }

  private openProvinceSelectionDialog(region: Region): void {
    this.dialog.open<ProvinceSelectionDialogComponent, ProvinceSelectionDialogOptions>(
      ProvinceSelectionDialogComponent,
      {
        ...PROVINCE_SELECTION_DIALOG_CONFIG,
        data: {
          region,
          replaceUrl: true,
        },
      },
    );
  }

  getChooseDoctorRouteParamsFromActivatedRoute(activatedRoute: ActivatedRoute): ChooseDoctorRouteParams {
    const companyId = this.getCompanyIdFromActivatedRoute(activatedRoute);
    const province = this.getProvinceFromActivatedRoute(activatedRoute);
    const billingType = this.getBillingTypeFromActivatedRoute(activatedRoute);

    const { doctorId, billingNumber, appointmentType } = activatedRoute.snapshot.queryParams;
    const doNotShowBillingAndProvinceDialog = activatedRoute.snapshot.queryParams.doNotShowBillingAndProvinceDialog === 'true';
    const billingGroupSelected = activatedRoute.snapshot.queryParams.billingGroupSelected === 'true';

    return {
      companyId,
      province,
      billingType,
      doctorId,
      appointmentType,
      billingNumber,
      doNotShowBillingAndProvinceDialog,
      billingGroupSelected,
    };
  }

  getCompanyIdFromActivatedRoute(activatedRoute: ActivatedRoute): string {
    const companyBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(activatedRoute, [CompanyBookingComponent]);
    return companyBookingRoute.snapshot.params.companyId;
  }

  getDoctorSearchFiltersFromChooseDoctorRouteParams(chooseDoctorRouteParams: ChooseDoctorRouteParams): DoctorSearchFilters {
    const {
      province,
      billingType,
      doctorId,
      appointmentType,
      billingNumber,
    } = chooseDoctorRouteParams;

    const doctorSearchQuery = this.doctorSearchQuery;

    return {
      province,
      billingType,
      doctorId,
      appointmentType,
      billingNumber,
      doctorSearchQuery,
    };
  }

  getDoctorAndFacetDataObservable(companyId: string, currentDoctorSearchFilters: Subject<DoctorSearchFilters>): Observable<DoctorFacetSearchData> {
    return currentDoctorSearchFilters.pipe(
      tap(() => { this.loadingDoctors = true; }),
      switchMap(() => timer(0, ChooseDoctorComponent.DOCTORS_AND_FACETS_REFRESH_INTERVAL_DURATION)),
      switchMap(() => this.doctorSearchService.retrieveLatestDoctorResultsFromSearchFilters(companyId, currentDoctorSearchFilters)),
      tap(() => { this.loadingDoctors = false; }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  getProvinceFromActivatedRoute(activatedRoute: ActivatedRoute): Province {
    try {
      const provinceRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(activatedRoute, [ProvinceBookingComponent]);
      return this.provinceService.parseQueryParamProvince(provinceRoute.snapshot.params.provinceAbbreviation);
    } catch (error) {
      return Province.ON;
    }
  }

  getBillingTypeFromActivatedRoute(activatedRoute: ActivatedRoute): BillingType {
    try {
      const billingTypeBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(activatedRoute, [BillingTypeBookingComponent]);
      return this.billingTypeService.parseBillingType(billingTypeBookingRoute.snapshot.params.billingType);
    } catch (error) {
      return BillingType.PRIVATE;
    }
  }

  async handleChooseDoctorRouteParamsChange(updatedChooseDoctorRouteParams: ChooseDoctorRouteParams): Promise<void> {
    this.chooseDoctorRouteParams = updatedChooseDoctorRouteParams;
    const doctorSearchFilters = this.getDoctorSearchFiltersFromChooseDoctorRouteParams(this.chooseDoctorRouteParams);
    this.updateDoctorSearchFiltersSubject(this.currentDoctorSearchFilters, doctorSearchFilters);
  }

  shouldShowBillingNumberDialog(chooseDoctorRouteParams: ChooseDoctorRouteParams): boolean {
    const {
      companyId,
      province,
      billingType,
      billingNumber,
      billingGroupSelected,
      doNotShowBillingAndProvinceDialog,
    } = chooseDoctorRouteParams;

    return (
      doNotShowBillingAndProvinceDialog === true &&
      province === Province.ON &&
      companyId === 'tiaHealth' &&
      billingType === BillingType.PUBLIC &&
      billingNumber === undefined &&
      billingGroupSelected !== true
    );
  }

  updateDoctorSearchFiltersSubject(currentDoctorSearchFilters: Subject<DoctorSearchFilters>, newDoctorSearchFilters: DoctorSearchFilters): void {
    currentDoctorSearchFilters.next(newDoctorSearchFilters);
  }

  updateQueryParams(updatedQueryParams: DoctorSearchFilterQueryParams, replaceUrl: boolean): void {
    const companyBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(this.activatedRoute, [CompanyBookingComponent]);
    const navigationExtras: NavigationExtras = {
      relativeTo: companyBookingRoute,
      queryParams: updatedQueryParams,
      replaceUrl,
    };

    const billingType = this.getBillingTypeFromActivatedRoute(this.activatedRoute);
    const provinceRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(this.activatedRoute, [ProvinceBookingComponent, InternationalBookingComponent]);
    const region = this.billingRegionService.getBillingRegion(provinceRoute.snapshot.params.provinceAbbreviation, billingType);

    this.bookingStepService.jumpToStep(BookingStep.CHOOSE_DOCTOR, {
      navigationExtras,
      pathParams: {
        region: region,
      },
    });
  }

  toggleQueryParamsWithMatchingValues(queryParams: Params, compareTo: Params): Params {
    const toggledQueryParams = { ...queryParams };

    Object.keys(compareTo).forEach((key) => {
      if (toggledQueryParams[key] === compareTo[key]) {
        toggledQueryParams[key] = null;
      }
    });

    return toggledQueryParams;
  }

  getAppointmentTypesFromDoctorFacetSearchData(doctorFacetSearchData: DoctorFacetSearchData): string[] {
    const filteredAppointmentTypes = doctorFacetSearchData.doctorSearchData.flatMap((doctor) => {
      const filteredAppointments = doctor.service.appointments.filter((appointment) => appointment.earliestAvailabilityForAppointment);

      return filteredAppointments.map((appointment) => appointment.type);
    });

    return Array.from(new Set(filteredAppointmentTypes)).sort((a, b) => a.localeCompare(b));
  }

  handleDoctorCardClicked(doctor: DoctorSearchData): void {
    const doctorId = doctor.doctorMetadata.id;
    const { appointmentType } = this.chooseDoctorRouteParams;

    if (appointmentType !== undefined) {
      this.navigateToChooseTimePage(appointmentType, doctor);
    } else {
      const updatedQueryParams = this.chooseDoctorRouteParams.doctorId === doctorId ?
        { doctorId: null } :
        { doctorId };
      this.updateQueryParams(updatedQueryParams, false);
    }
  }

  async handleAppointmentTypeClicked(appointmentType: string): Promise<void> {
    const { doctorId } = this.chooseDoctorRouteParams;
    const doctor = await this.getDoctorDataFromDoctorsAndFacetsObservable(doctorId, this.doctorsAndFacets$);

    if (doctor !== undefined) {
      this.navigateToChooseTimePage(appointmentType, doctor);
    } else {
      const updatedQueryParams = this.chooseDoctorRouteParams.appointmentType === appointmentType ?
        { appointmentType: null } :
        { appointmentType };
      this.updateQueryParams(updatedQueryParams, false);
    }
  }

  handleClearFiltersClicked(): void {
    this.clearDoctorSearchQuery();
    this.updateQueryParams({
      doctorId: null,
      appointmentType: null,
    }, false);
  }

  handleEditAppointmentTypeClicked(): void {
    this.clearDoctorSearchQuery();
    this.updateQueryParams({ appointmentType: null }, false);
  }

  private clearDoctorSearchQuery(): void {
    this.doctorSearchQuery = undefined;
    this.searchComponent?.clearSearchInput();
  }

  handleSearchInputChanged(doctorSearchQuery: string): void {
    this.doctorSearchQuery = doctorSearchQuery;

    const newDoctorSearchFilters = this.getDoctorSearchFiltersFromChooseDoctorRouteParams(this.chooseDoctorRouteParams);
    this.updateDoctorSearchFiltersSubject(this.currentDoctorSearchFilters, newDoctorSearchFilters);
  }

  async getDoctorDataFromDoctorsAndFacetsObservable(doctorId: string | undefined, doctorsAndFacets$: Observable<DoctorFacetSearchData>): Promise<DoctorSearchData | undefined> {
    const doctorsAndFacetsData = await firstValueFrom(doctorsAndFacets$);

    return doctorsAndFacetsData.doctorSearchData.find((doctorData) => {
      return doctorData.doctorMetadata.id === doctorId;
    });
  }

  getServiceByTypeFromDoctorSearchData(serviceType: string, doctorSearchData: DoctorSearchData): Appointment | undefined {
    if (doctorSearchData.service.appointments) {
      return doctorSearchData.service.appointments.find((appointment) => {
        return appointment.type === serviceType;
      });
    }
  }

  getServiceIdForServiceFromDoctorData(serviceType: string, doctorData: DoctorSearchData): string {
    if (doctorData.service.appointments) {
      const foundMatchingDoctorService = doctorData.service.appointments.find((appointment) => {
        return appointment.type === serviceType;
      });

      if (foundMatchingDoctorService?.id) {
        return foundMatchingDoctorService.id;
      }
    }

    throw new Error('Was not able to find matching service in the selected doctor\'s data');
  }

  navigateToChooseTimePage(serviceType: string, doctor: DoctorSearchData): void {
    const serviceId = this.getServiceIdForServiceFromDoctorData(serviceType, doctor);
    const companyBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(this.activatedRoute, [CompanyBookingComponent]);
    const provinceBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(this.activatedRoute, [ProvinceBookingComponent, InternationalBookingComponent]);
    const province = provinceBookingRoute.snapshot.params.provinceAbbreviation;
    const billingType = this.billingTypeService.parseBillingType(this.getBillingTypeFromActivatedRoute(this.activatedRoute));
    const region = this.billingRegionService.getBillingRegion(province, billingType);

    this.bookingStepService.jumpToStep(BookingStep.CHOOSE_TIME, {
      navigationExtras: {
        relativeTo: companyBookingRoute,
      },
      pathParams: {
        region,
        doctorId: doctor.doctorMetadata.id,
        serviceId,
      },
    });
  }

  areThereAvailableServices(doctorsAndFacets: DoctorFacetSearchData | null): boolean {
    const availableServices = doctorsAndFacets?.facetResponseData.find((facetResponseData) => facetResponseData.facetName === AggregationName.SERVICE_TYPE_AGG)?.facetValueData;
    return availableServices !== undefined && availableServices.length > 0;
  }

  areThereAvailableDoctors(doctorsAndFacets: DoctorFacetSearchData | null): boolean {
    const availableDoctors = doctorsAndFacets?.doctorSearchData;
    return availableDoctors !== undefined && availableDoctors.length > 0;
  }

  getPriceRangeByAppointmentTypeName(appointmentTypeName: string, doctorsAndFacets: DoctorFacetSearchData | null): { minimumPrice: number, maximumPrice: number } {
    if (doctorsAndFacets === null) {
      return { minimumPrice: 0, maximumPrice: 0 };
    }

    const doctors = doctorsAndFacets.doctorSearchData;
    const appointmentTypes = doctors.flatMap((doctor) => doctor.service.appointments);
    const sameNameAppointmentTypes = appointmentTypes.filter((appointmentType) => appointmentType.type === appointmentTypeName);
    const prices = sameNameAppointmentTypes
      .map((appointmentType) => appointmentType.price)
      .filter((price): price is number => price !== undefined)
      .sort((priceA, priceB) => priceA - priceB);

    return { minimumPrice: prices[0] ?? 0, maximumPrice: prices[prices.length-1] ?? 0 };
  }

  async handleQuickBookClicked(quickBookDoctorSearchData: DoctorSearchData | undefined): Promise<void> {
    if (quickBookDoctorSearchData !== undefined) {
      const { province, appointmentType, billingType } = this.getChooseDoctorRouteParamsFromActivatedRoute(this.activatedRoute);
      const service = this.getServiceByTypeFromDoctorSearchData(appointmentType ?? '* General Appointment', quickBookDoctorSearchData);

      if (service !== undefined) {
        const serviceMedium = this.getQuickBookMediumByService(service);
        try {
          const draftAppointment = await this.appointmentReservationService.reserveAppointmentSlot({
            province,
            doctorId: quickBookDoctorSearchData.doctorMetadata.id,
            serviceId: service.id,
            serviceMedium: this.appointmentMediumService.parseAppointmentMedium(serviceMedium),
            locationId: 'virtual',
            startTime: quickBookDoctorSearchData.earliestAvailableDate.getTime(),
            billingType,
            lookAheadMinutes: QuickBookComponent.LOOK_AHEAD_MINUTES,
            draftOrigin: DraftOrigin.QUICK_BOOK,
            bookingSiteUrl: window.location.href,
            bookingSite: INSIG_BOOKING_SITE,
          });
          const companyBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(this.activatedRoute, [CompanyBookingComponent]);
          this.bookingStepService.jumpToStep(BookingStep.LOGIN, {
            navigationExtras: {
              relativeTo: companyBookingRoute,
            },
            pathParams: {
              draftAppointmentId: draftAppointment.appointmentId,
            },
          });
        } catch (error) {
          this.snackBar.open(ChooseDoctorComponent.QUICK_BOOK_ERROR_MESSAGE, undefined, { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS });
          this.handleChooseDoctorRouteParamsChange(this.chooseDoctorRouteParams);
        }
      }
    }
  }

  getQuickBookMediumByService(service: Appointment): AppointmentServiceMedium {
    if (service.mediums.includes(AppointmentServiceMedium.Video)) {
      return AppointmentServiceMedium.Video;
    } else if (service.mediums.includes(AppointmentServiceMedium.Phone)) {
      return AppointmentServiceMedium.Phone;
    } else {
      return AppointmentServiceMedium.Messaging;
    }
  }

  getIsPublicServiceChargedByAppointmentTypeName(appointmentTypeName: string, doctorSearchDatas: DoctorSearchData[]): boolean {
    return doctorSearchDatas.some((doctorSearchData) => {
      return doctorSearchData.service.appointments
        .some((appointment) => appointment.type === appointmentTypeName && appointment.isPublicServiceCharged);
    });
  }

  getIsDoctorAndFacetsEmpty$(doctorsAndFacets$: Observable<DoctorFacetSearchData>): Observable<boolean> {
    return doctorsAndFacets$.pipe(map((doctorsAndFacets) =>
      !this.areThereAvailableServices(doctorsAndFacets) ||
      !this.areThereAvailableDoctors(doctorsAndFacets),
    ));
  }

  isServiceAvailableOnSameDayAsDoctorEarliestAvailableDate(doctorId: string, appointmentType: string, doctorFacetSearchData: DoctorFacetSearchData): boolean {
    const doctor = this.getDoctorFromDoctorFacetSearchData(doctorId, doctorFacetSearchData);
    if (doctor === undefined) {
      return false;
    }
    const doctorEarliestAvailableDate = doctor.earliestAvailableDate;

    const serviceEarliestAvailableDate = this.getEarliestAvailableDateForDoctorAppointment(doctorId, appointmentType, doctorFacetSearchData);
    if (serviceEarliestAvailableDate === undefined) {
      return false;
    }

    return this.dateAndTimeService.getLocalCalendarDaysDifferenceBetweenTwoDates(serviceEarliestAvailableDate, doctorEarliestAvailableDate) === 0;
  }

  getEarliestAvailableDateForDoctorAppointment(doctorId: string, appointmentType: string, doctorFacetSearchData: DoctorFacetSearchData): Date | undefined {
    const doctor = this.getDoctorFromDoctorFacetSearchData(doctorId, doctorFacetSearchData);
    if (doctor === undefined) {
      return;
    }

    const service = this.getServiceByTypeFromDoctorSearchData(appointmentType, doctor);
    return service?.earliestAvailabilityForAppointment;
  }

  getDoctorFromDoctorFacetSearchData(doctorId: string, doctorFacetSearchData: DoctorFacetSearchData): DoctorSearchData | undefined {
    return doctorFacetSearchData.doctorSearchData.find((doctor) => doctor.doctorMetadata.id === doctorId);
  }
}
