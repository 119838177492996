import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, Output, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ExtendedSlotData } from '@insig-health/services/doctor/doctor.service';
import { PatientProfileService } from '@insig-health/services/patient-profile/patient-profile.service';
import { SNACK_BAR_AUTO_DISMISS_MILLISECONDS } from '@insig-health/config/angular-material.config';
import { GcpIpAuthService } from '@insig-health/gcp-ip/gcp-ip-auth.service';
import { AppointmentReservationService } from '../../services/appointment-reservation/appointment-reservation.service';
import { firstValueFrom, Subscription } from 'rxjs';
import { ThemeService } from '../../services/theme/theme.service';

export interface ConfirmBookingQueryParams {
  doctorId: string | undefined;
  appointmentMedium: string | undefined;
  timeSlot: ExtendedSlotData | undefined;
  serviceId: string | undefined;
  serviceType: string | undefined;
  draftAppointmentId: string | undefined;
}

export enum LoginState {
  LOGIN_WIDGET,
  ACCOUNT_CREATION,
  ADD_PATIENT_INFO_TO_ACCOUNT
}

@Component({
  selector: 'insig-booking-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnDestroy {
  private gcpIpAuthService = inject(GcpIpAuthService);
  private appointmentReservationService = inject(AppointmentReservationService);
  private changeDetector = inject(ChangeDetectorRef);
  private patientProfileService = inject(PatientProfileService);
  private themeService = inject(ThemeService);
  private snackbar = inject(MatSnackBar);
  public LoginState = LoginState;
  public state = LoginState.LOGIN_WIDGET;
  public isLogoVisible = this.themeService.getCurrentThemeConfig().loginWidget.isLogoVisible;

  @Output() loggedIn = new EventEmitter<void>();

  private isLoggedInSubscription: Subscription;

  constructor(
  ) {
    this.isLoggedInSubscription = this.gcpIpAuthService.isLoggedIn().subscribe((isLoggedIn) => {
      if (!isLoggedIn && this.state === LoginState.ADD_PATIENT_INFO_TO_ACCOUNT) {
        this.state = LoginState.LOGIN_WIDGET;
      }
    });
  }

  ngOnDestroy(): void {
    this.isLoggedInSubscription.unsubscribe();
  }

  async handleLoggedIn(): Promise<void> {
    const firebaseUser = this.gcpIpAuthService.getCurrentUser();
    if (firebaseUser === null) {
      throw new Error('Firebase user is null');
    }

    if (this.checkIfDoctorToken()) {
      await this.handleDoctorLoggedIn();
      return;
    }

    if (await this.patientProfileService.isPatientProfileComplete(firebaseUser.uid)) {
      this.loggedIn.emit();
    } else {
      this.state = LoginState.ADD_PATIENT_INFO_TO_ACCOUNT;
    }
  }

  checkIfDoctorToken(): boolean {
    // TODO reimplement
    return false;
  }

  async handleDoctorLoggedIn(): Promise<void> {
    this.snackbar.open('Please create or sign in with a patient account', undefined, {
      duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS,
    });
    await this.gcpIpAuthService.signOut();
  }

  handleSignUpButtonClicked(): void {
    this.state = this.LoginState.ACCOUNT_CREATION;
    this.changeDetector.detectChanges();
  }

  handleBackToLoginButtonClicked(): void {
    this.state = this.LoginState.LOGIN_WIDGET;
    this.changeDetector.detectChanges();
  }

  async handleRegistrationRegisterButtonClicked(event: { email: string, password: string }): Promise<void> {
    const { email, password } = event;

    try {
      await this.gcpIpAuthService.signIn(email, password);
      const currentReservedAppointmentSlot = await firstValueFrom(this.appointmentReservationService.getCurrentReservedAppointmentSlot());
      if (currentReservedAppointmentSlot !== undefined && currentReservedAppointmentSlot.canDraftAppointmentExpiryBeExtended === true) {
        await this.appointmentReservationService.extendCurrentReservedAppointmentSlot();
      }

      this.state = this.LoginState.ADD_PATIENT_INFO_TO_ACCOUNT;
      this.changeDetector.detectChanges();
    } catch (error) {
      if (error instanceof Error) {
        this.snackbar.open(error.message, undefined, { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS });
      } else {
        console.error(error);
      }
    }
  }
}
