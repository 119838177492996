import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'insig-ui-radio-input',
  templateUrl: './insig-radio-input.component.html',
  styleUrls: ['./insig-radio-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: InsigRadioInputComponent,
  }],
})
export class InsigRadioInputComponent implements ControlValueAccessor {
  public onChange?: (value: string) => void;
  public onTouched?: () => void;

  public _isChecked = false;
  public _isDisabled = false;

  @Input() set isChecked(isChecked: boolean | 'true' | 'false') {
    this._isChecked = coerceBooleanProperty(isChecked);
  }
  @Input() set isDisabled(isDisabled: boolean | 'true' | 'false') {
    this._isDisabled = coerceBooleanProperty(isDisabled);
  }

  @Input() radioGroup?: string;
  @Input() label?: string;
  @Input() value?: string;

  writeValue(isChecked: boolean): void {
    this._isChecked = isChecked;
  }

  registerOnChange(onChange: (value: string) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this._isDisabled = isDisabled;
  }

  handleChange(event: Event): void {
    this._isChecked = (event.target as HTMLInputElement).checked;
    this.onChange?.((event.target as HTMLInputElement).value);
    this.onTouched?.();
  }

}
