<nav>
  <div class="mainNav d-flex align-items-center justify-content-between">

    <div role="button" (click)="handleLogoClicked()">
      <insig-ui-image-container
        height="35px"
        [imageUrl]="themeLogoUrl ?? companyBranding"
        fallbackImageUrl="/assets/images/home-icon.svg"
        alt="Logo"
      >
      </insig-ui-image-container>
    </div>

    <insig-booking-stepper class="flex-grow-1"></insig-booking-stepper>

    <div role="button" class="d-block d-md-none sidenavToggle" [ngClass]="{ 'open': isSidenavOpen }" (click)="handleToggleSidenavClicked()"></div>

    <insig-ui-button *ngIf="isRegionSelectorVisible" class="d-none d-md-block" stylePreset="flat" (click)="handleRegionSelectorClicked()">
      <span class="notranslate textSecondary bold font-size-sm">
        {{ (currentRegion$ | async)?.regionName }}
      </span>
    </insig-ui-button>

    <insig-booking-language-dropdown class="px-3 d-none d-md-block"></insig-booking-language-dropdown>

    <div class="d-none d-md-block">
      <insig-ui-button *ngIf="(isLoggedIn$ | async) === false && isLoginButtonAvailable()"
        stylePreset="tertiary"
        (click)="handleLoginClicked()"
      >
        <img class="pe-1" src="assets/img/ico-login.svg" alt="" /><span class="font-size-sm">Log in</span>
      </insig-ui-button>
      <insig-ui-button *ngIf="isLoggedIn$ | async" stylePreset="tertiary" insigBookingLogoutButton>Logout</insig-ui-button>
    </div>
  </div>
</nav>

<insig-booking-time-alert *ngIf="showTimer" class="w-100"
  [currentReservedAppointmentSlot]="currentReservedAppointmentSlot"
></insig-booking-time-alert>
