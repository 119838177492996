import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges, inject } from '@angular/core';
import { DateAndTimeService } from '@insig-health/services/date-and-time/date-and-time.service';

@Component({
  selector: 'insig-booking-daily-slot-header',
  templateUrl: './daily-slot-header.component.html',
})
export class DailySlotHeaderComponent implements OnChanges {
  public dateAndTimeService = inject(DateAndTimeService);
  private changeDetector = inject(ChangeDetectorRef);
  @Input() date: Date | undefined;

  public dayAndMonth: string | undefined;
  public loading = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.date) {
      const dateChanges = changes.date;
      const isDateDefined = dateChanges.currentValue !== undefined && dateChanges.currentValue !== null;
      const isDateChanged = dateChanges.currentValue?.getTime() !== dateChanges.previousValue?.getTime();
      if (isDateDefined && isDateChanged) {
        this.loading = true;
        this.changeDetector.detectChanges();
        this.dayAndMonth = this.dateAndTimeService.getDayAndMonth(changes.date.currentValue);
        this.loading = false;
        this.changeDetector.detectChanges();
      }
    }
  }
}
