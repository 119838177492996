import { Injectable, inject } from '@angular/core';
import { DoctorSearchFilters } from '../../app/choose-doctor/choose-doctor.component';
import { DoctorFacetSearchData, DoctorService } from '@insig-health/services/doctor/doctor.service';
import { Observable, Subject } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DoctorSearchService {
  private doctorService = inject(DoctorService);

  retrieveLatestDoctorResultsFromSearchFilters(companyId: string, doctorSearchFiltersSubject: Subject<DoctorSearchFilters>): Observable<DoctorFacetSearchData> {
    return doctorSearchFiltersSubject.pipe(
      debounceTime(400),
      switchMap((doctorSearchFilters) => {
        return this.getDoctorAndFacetData(companyId, doctorSearchFilters);
      }),
    );
  }

  async getDoctorAndFacetData(companyId: string, doctorSearchFilters: DoctorSearchFilters): Promise<DoctorFacetSearchData> {
    const { appointmentType, doctorSearchQuery, province, doctorId, billingType, billingNumber } = doctorSearchFilters;
    return this.doctorService.getDoctorAndFacetData({
      companyId,
      billingType,
      province,
      appointmentType: appointmentType,
      queryString: doctorSearchQuery,
      doctorId,
      billingNumber,
    });
  }
}
