/// <reference path="./aws-waf-captcha.d.ts" />

import { Inject, Injectable, InjectionToken, inject } from '@angular/core';
import { AsyncUtilitiesService } from '../async-utilities/async-utilities.service';

export const AWS_WAF_CAPTCHA_API_KEY_TOKEN = new InjectionToken<string>(
  'No AWS WAF Captcha API Key provided. Provide one in the root module.'
);

export const AWS_WAF_CAPTCHA_SCRIPT_URL_TOKEN = new InjectionToken<string>(
  'No AWS WAF Captcha script URL provided. Provide one in the root module.'
);

@Injectable({
  providedIn: 'root',
})
export class AwsWafCaptchaService {
      private apiKey = inject<string>(AWS_WAF_CAPTCHA_API_KEY_TOKEN);
      private scriptUrl = inject<string>(AWS_WAF_CAPTCHA_SCRIPT_URL_TOKEN);
      private asyncUtilities = inject(AsyncUtilitiesService);
  static readonly INTEGRATION_MISSING_ERROR_STRING = 'AWS WAF Captcha integration not found. Make sure the script is loaded.';

  constructor(
) {
    this.asyncUtilities.retryPromise(() => this.loadCaptchaScript(), 20, 100).catch((error) => {
      console.error(error);
    });
  }

  getIntegration(): typeof AwsWafIntegration {
    if (typeof AwsWafIntegration === 'undefined') {
      throw new Error(AwsWafCaptchaService.INTEGRATION_MISSING_ERROR_STRING);
    }
    return AwsWafIntegration;
  }

  async loadCaptchaScript(): Promise<void> {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = this.scriptUrl;
      script.addEventListener('load', () => {
        resolve();
      });
      script.addEventListener('error', (error) => {
        document.body.removeChild(script);
        if (error instanceof Event) {
          reject(new Error(`Failed to load AWS WAF Captcha script: ${(error.target as HTMLScriptElement)?.src}`));
        } else {
          reject(new Error(`Failed to load AWS WAF Captcha script: ${error}`));
        }
      });
      document.body.appendChild(script);
    });
  }

  async renderCaptcha(container: HTMLElement): Promise<string> {
    return new Promise((resolve) => {
      AwsWafCaptcha.renderCaptcha(container, {
        apiKey: this.apiKey,
        onSuccess: (wafToken) => {
          resolve(wafToken);
        },
      });
    });
  }
}
