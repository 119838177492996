import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { BillingTypeBookingComponent } from '../../app/billing-type-booking/billing-type-booking.component';
import { InternationalBookingComponent } from '../../app/international-booking/international-booking.component';
import { ProvinceBookingComponent } from '../../app/province-booking/province-booking.component';
import { Province, ProvinceService } from '@insig-health/services/province/province.service';
import { BillingType, BillingTypeService } from '../billing-type/billing-type.service';
import { BookingStepService } from '../booking-step/booking-step.service';

export type BillingRegion = InternationalRegion | ProvinceRegion;

export enum RegionType {
  INTERNATIONAL = 'international',
  PROVINCE = 'province',
}

export interface InternationalRegion {
  type: RegionType.INTERNATIONAL;
  billingType: BillingType.PRIVATE;
}

export interface ProvinceRegion {
  type: RegionType.PROVINCE;
  provinceAbbreviation: Province;
  billingType: BillingType;
}

const INTERNATIONAL_PRIVATE_REGION: InternationalRegion = {
  type: RegionType.INTERNATIONAL,
  billingType: BillingType.PRIVATE,
} as const;

@Injectable({
  providedIn: 'root',
})
export class BillingRegionService {
  private provinceService = inject(ProvinceService);
  private bookingStepService = inject(BookingStepService);
  private billingTypeService = inject(BillingTypeService);

  getBillingRegion(regionCode: string, billingType: BillingType): BillingRegion {
    try {
      const provinceAbbreviation = this.provinceService.parseQueryParamProvince(regionCode);
      return {
        type: RegionType.PROVINCE,
        billingType,
        provinceAbbreviation,
      };
    } catch (error) {
      return INTERNATIONAL_PRIVATE_REGION;
    }
  }

  getBillingRegionFromActivatedRouteSnapshot(activatedRouteSnapshot: ActivatedRouteSnapshot): BillingRegion {
    try {
      const provinceBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(activatedRouteSnapshot, [ProvinceBookingComponent, InternationalBookingComponent]);
      const province = provinceBookingRoute.params.provinceAbbreviation;

      const billingTypeBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(activatedRouteSnapshot, [BillingTypeBookingComponent]);
      const billingType = this.billingTypeService.parseBillingType(billingTypeBookingRoute.params.billingType);

      return this.getBillingRegion(province, billingType);
    } catch (_error) {
      return INTERNATIONAL_PRIVATE_REGION;
    }
  }

  isBillingRegionEqual(billingRegionA: BillingRegion, billingRegionB: BillingRegion): boolean {
    if (billingRegionA.type !== billingRegionB.type) {
      return false;
    }

    if (billingRegionA.type === RegionType.INTERNATIONAL || billingRegionB.type === RegionType.INTERNATIONAL) {
      return true;
    }

    return billingRegionA.provinceAbbreviation === billingRegionB.provinceAbbreviation &&
      billingRegionA.billingType === billingRegionB.billingType;
  }
}
