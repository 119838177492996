import { Component, HostBinding, Input, inject } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'insig-ui-button',
  templateUrl: './insig-button.component.html',
  styleUrls: ['./insig-button.component.scss'],
})
export class InsigButtonComponent {
      public router = inject(Router);
  @HostBinding('attr.role') role = 'button';
  @HostBinding('attr.target') @Input() target = '_self';

  @Input() routerLink?: string;
  @Input() externalLink?: string;
  @Input() iconSrc?: string;
  @Input() iconHeight?: string = undefined;
  @Input() disabled = false;
  @Input() stylePreset: 'primary' | 'secondary' | 'tertiary' | 'pink' | 'white' | 'fullWhite' | 'gray' | 'flat' = 'primary';
  @Input() type = 'button';

  handleClick(): void {
    if (!this.disabled) {
      if (this.routerLink) {
        this.router.navigateByUrl(this.routerLink);
      }
      if (this.externalLink) {
        window?.open(this.externalLink, this.target)?.focus();
      }
    }
  }

}
