import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { CompanyBookingComponent } from '../../app/company-booking/company-booking.component';
import { BillingRegionService, RegionType } from '../../services/billing-region/billing-region.service';
import { BookingStepService } from '../../services/booking-step/booking-step.service';
import { ThemeService } from '../../services/theme/theme.service';

@Injectable({
  providedIn: 'root',
})
export class ThemeValidBillingRegionsGuard {
  private themeService = inject(ThemeService);
  private billingRegionService = inject(BillingRegionService);
  private bookingStepService = inject(BookingStepService);
  private router = inject(Router);

  canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot): boolean | UrlTree {
    const themeConfig = this.themeService.getCurrentThemeConfig();
    const validBillingRegions = themeConfig.validBillingRegions;
    if (validBillingRegions.length === 0) {
      return true;
    }

    const deepestRouteChild = this.bookingStepService.getDeepestActivatedRouteChild(activatedRouteSnapshot);
    const billingRegion = this.billingRegionService.getBillingRegionFromActivatedRouteSnapshot(deepestRouteChild);

    if (validBillingRegions.some((validBillingRegion) => this.billingRegionService.isBillingRegionEqual(billingRegion, validBillingRegion))) {
      return true;
    } else {
      const companyBookingRoute = this.bookingStepService.getActivatedRouteOfComponentType(deepestRouteChild, CompanyBookingComponent);

      const defaultBillingRegion = themeConfig.defaultBillingRegion;
      if (defaultBillingRegion.type === RegionType.PROVINCE) {
        const urlTree = this.router.createUrlTree([
          'company',
          companyBookingRoute.params.companyId,
          'province',
          defaultBillingRegion.provinceAbbreviation,
          'billing',
          defaultBillingRegion.billingType,
          'chooseDoctor',
        ], {
          queryParams: activatedRouteSnapshot.queryParams,
        });

        return urlTree;
      } else {
        const urlTree = this.router.createUrlTree([
          'company',
          companyBookingRoute.params.companyId,
          'international',
          'billing',
          defaultBillingRegion.billingType,
          'chooseDoctor',
        ], {
          queryParams: activatedRouteSnapshot.queryParams,
        });

        return urlTree;
      }
    }
  }
}
