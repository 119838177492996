import { Component, inject } from '@angular/core';
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'insig-booking-navigation-warning-dialog',
  templateUrl: './navigation-warning-dialog.component.html',
})
export class NavigationWarningDialogComponent {
  static readonly DIALOG_MAX_WIDTH = '480px';
  static readonly DIALOG_WIDTH = '100vw';

  static readonly DEFAULT_DIALOG_ID = 'insig-booking-navigation-warning-dialog-id';
  static readonly DEFAULT_DIALOG_CONFIG: MatDialogConfig = {
    id: NavigationWarningDialogComponent.DEFAULT_DIALOG_ID,
    disableClose: true,
    width: NavigationWarningDialogComponent.DIALOG_WIDTH,
    maxWidth:  NavigationWarningDialogComponent.DIALOG_MAX_WIDTH,
  };

  public dialogRef = inject<MatDialogRef<NavigationWarningDialogComponent>>(MatDialogRef<NavigationWarningDialogComponent>);
}
