import { Component, Input, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@insig-health/services/translate/translate.service';
import { map } from 'rxjs/operators';
import { BookingStep, BookingStepService } from '../../services/booking-step/booking-step.service';
import { CurrentProvinceService } from '../../services/current-province/current-province.service';
import { GcpIpAuthService } from '@insig-health/gcp-ip/gcp-ip-auth.service';
import { Observable, firstValueFrom, Subscription } from 'rxjs';
import { Region } from '@insig-health/services/location/location.service';
import { ThemeService } from '../../services/theme/theme.service';
import { MatDialog } from '@angular/material/dialog';
import { PROVINCE_SELECTION_DIALOG_CONFIG, ProvinceSelectionDialogComponent, ProvinceSelectionDialogOptions } from '../choose-doctor/components/province-selection-dialog/province-selection-dialog.component';
import { BillingRegion, BillingRegionService } from '../../services/billing-region/billing-region.service';
import { BillingTypeService } from '../../services/billing-type/billing-type.service';

enum NestedSidenav {
  NONE = 'none',
  LOCATION = 'location',
  LANGUAGE = 'language',
}

export const MAXIMUM_LOCATION_NAME_LENGTH = 16;

@Component({
  selector: 'insig-booking-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnDestroy, OnInit {
  private gcpIpAuthService = inject(GcpIpAuthService);
  private bookingStepService = inject(BookingStepService);
  private currentProvinceService = inject(CurrentProvinceService);
  private translateService = inject(TranslateService);
  private themeService = inject(ThemeService);
  private route = inject(Router);
  private activatedRoute = inject(ActivatedRoute);
  private dialog = inject(MatDialog);
  private billingTypeService = inject(BillingTypeService);
  private billingRegionService = inject(BillingRegionService);
  @Input() sidenav: MatSidenav | undefined;
  @ViewChild('nestedSidenav', { static: true }) nestedSidenav: MatSidenav | undefined;

  public isLoggedIn$ = this.gcpIpAuthService.isLoggedIn();
  public currentRegion$ = new Observable<string>();
  public currentLanguage$ = this.translateService.getCurrentLanguage();

  public NestedSidenav = NestedSidenav;
  public currentNestedSidenav = NestedSidenav.NONE;

  private _currentBookingStepChangedSubscription: Subscription;

  public isRegionSelectorVisible = false;

  constructor(
  ) {
    this._currentBookingStepChangedSubscription = this.bookingStepService.bookingStepChange$.subscribe((bookingStep) => {
      const showRegionSelectorBasedOnTheme = this.themeService.getCurrentThemeConfig().isRegionSelectorVisible;
      const showRegionSelectorBasedOnBookingStep = bookingStep === BookingStep.CHOOSE_DOCTOR || bookingStep === BookingStep.CHOOSE_TIME;
      this.isRegionSelectorVisible = showRegionSelectorBasedOnTheme && showRegionSelectorBasedOnBookingStep;
    });
  }

  async ngOnInit(): Promise<void> {
    this.currentRegion$ = this.currentProvinceService.getCurrentRegion().pipe(
      map((region) => this.getLengthBoundedRegionName(region, MAXIMUM_LOCATION_NAME_LENGTH)),
    );
    this.sidenav?._animationEnd.subscribe(() => this.closeNestedSidenav());
  }

  ngOnDestroy(): void {
    this._currentBookingStepChangedSubscription.unsubscribe();
  }

  closeNestedSidenav(): void {
    this.nestedSidenav?.close();
  }

  isLoginButtonAvailable(): boolean {
    return this.bookingStepService.isLoginAvailable(this.activatedRoute);
  }

  handleLoginClicked(): void {
    this.route.navigate(['login'], { relativeTo: this.bookingStepService.getDeepestActivatedRouteChild(this.activatedRoute) });
    this.sidenav?.close();
  }

  async handleLocationClicked(): Promise<void> {
    const dialogRef = this.dialog.open<ProvinceSelectionDialogComponent, ProvinceSelectionDialogOptions, BillingRegion>(
      ProvinceSelectionDialogComponent,
      {
        ...PROVINCE_SELECTION_DIALOG_CONFIG,
        data: {
          clearDoctorSearchQueryParams: true,
          replaceUrl: false,
        },
      },
    );
    const billingRegion = await firstValueFrom(dialogRef.afterClosed());
    if (billingRegion) {
      this.sidenav?.close();
    }
  }

  handleLanguageClicked(): void {
    this.currentNestedSidenav = NestedSidenav.LANGUAGE;
    this.nestedSidenav?.toggle();
  }

  getLengthBoundedRegionName(region: Region, length: number): string {
    if (region.regionName.length > length) {
      return region.regionAbbreviation;
    } else {
      return region.regionName;
    }
  }
}
