import { Directive, ElementRef, OnDestroy, inject } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[insigBookingScrollbarPadding]',
})
export class ScrollbarPaddingDirective implements OnDestroy {
  static readonly LEFT_PADDING_CLASS_NAME = 'insig-scrollbar-padding-left';
  static readonly RIGHT_PADDING_CLASS_NAME = 'insig-scrollbar-padding-right';
  static readonly DEBOUNCE_MS = 1000;

  private elementRef = inject(ElementRef);

  private resizeObserver: ResizeObserver;
  private debounced = false;

  constructor(
  ) {
    const hostElement = this.elementRef.nativeElement as HTMLElement;
    hostElement.classList.add(ScrollbarPaddingDirective.LEFT_PADDING_CLASS_NAME);

    this.resizeObserver = new ResizeObserver(() => {
      if (!this.debounced) {
        this.handleResize(hostElement);
        this.debounced = true;
        setTimeout(() => {
          this.debounced = false;
        }, ScrollbarPaddingDirective.DEBOUNCE_MS);
      }
    });
    this.resizeObserver.observe(hostElement);
  }

  ngOnDestroy(): void {
    this.resizeObserver.disconnect();
  }

  private handleResize(hostElement: HTMLElement): void {
    if (this.isElementScrollable(hostElement)) {
      hostElement.classList.remove(ScrollbarPaddingDirective.RIGHT_PADDING_CLASS_NAME);
    } else {
      hostElement.classList.add(ScrollbarPaddingDirective.RIGHT_PADDING_CLASS_NAME);
    }
  }

  private isElementScrollable(element: HTMLElement): boolean {
    const { scrollHeight, clientHeight } = element;
    return scrollHeight > clientHeight;
  }
}
