<mat-sidenav-container class="h-100 bg-white">
  <mat-sidenav #nestedSidenav class="w-100" mode="over" position="end" autoFocus="false">
    <insig-booking-language-sidenav *ngIf="currentNestedSidenav === NestedSidenav.LANGUAGE" [sidenav]="nestedSidenav"></insig-booking-language-sidenav>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="h-100 d-flex flex-column sidenavContent">
      <div class="p-3 bold">Settings</div>
      
      <button *ngIf="isRegionSelectorVisible" class="p-3 d-flex justify-content-between" (click)="handleLocationClicked()">
        <span class="textSecondary bold">Location</span>
        <div class="d-flex gap-3">
          <span class="notranslate text-dark">{{ currentRegion$ | async }}</span>
          <span class="text-decoration-underline textBlue bold">Edit</span>
        </div>
      </button>

      <hr class="m-0">

      <button class="p-3 d-flex justify-content-between" (click)="handleLanguageClicked()">
        <span class="textSecondary bold">Language</span>
        <div class="d-flex gap-3">
          <span class="text-dark">{{ currentLanguage$ | async }}</span>
          <span class="text-decoration-underline textBlue bold">Edit</span>
        </div>
      </button>

      <hr class="m-0">

      <div class="ps-3 py-3 bold">
        <a *ngIf="(isLoggedIn$ | async) === false && isLoginButtonAvailable()"
          href="javascript:void(0);"
          role="button"
          (click)="handleLoginClicked()"
        >
          Login
        </a>
    
        <a *ngIf="isLoggedIn$ | async"
          insigBookingLogoutButton
          href="javascript:void(0);"
          role="button"
        >
          Logout
        </a>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
