import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { JAVA_BACKEND_ENDPOINT } from '@insig-health/config/config';
import { IpLocationData } from 'insig-types/ip-location';
import { firstValueFrom, map } from 'rxjs';

export interface Region {
  countryName?: string;
  countryAbbreviation: string;
  regionName: string;
  regionAbbreviation: string;
}

@Injectable({
  providedIn: 'root',
})
export class LocationService {
      private http = inject(HttpClient);
  private getIpLocationUrl = JAVA_BACKEND_ENDPOINT + 'ip-location';
  private headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' });

  async getRegion(): Promise<Region> {
    return firstValueFrom(this.http
      .get<IpLocationData>(this.getIpLocationUrl, {
        headers: this.headers,
      }).pipe(map((ipLocation) => {
        if (ipLocation) {
          return this.getRegionFromIpLocation(ipLocation);
        } else {
          return {
            countryName: 'Canada',
            countryAbbreviation: 'CA',
            regionName: 'Ontario',
            regionAbbreviation: 'ON',
          };
        }
      })),
    ).catch((error) => {
      console.error(error);
      throw error;
    });
  }

  private getRegionFromIpLocation(ipLocation: IpLocationData): Region {
    return {
      countryName: ipLocation.country_name,
      countryAbbreviation: ipLocation.country_code,
      regionName: ipLocation.region_name,
      regionAbbreviation: ipLocation.region_code,
    };
  }
}
