import { Component, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'insig-booking-new-tia-patient-terms-dialog',
  templateUrl: './new-tia-patient-terms-dialog.component.html',
})
export class NewTiaPatientTermsDialogComponent {
  public dialogRef = inject<MatDialogRef<NewTiaPatientTermsDialogComponent>>(MatDialogRef<NewTiaPatientTermsDialogComponent>);
  public titleLine = 'Terms & Conditions';
}
