import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CompanyBookingComponent } from '../company-booking/company-booking.component';
import { AppointmentServiceMedium } from '@insig-health/api/doctor-booking-flow-api-v1';
import { DoctorSearchData, DoctorService } from '@insig-health/services/doctor/doctor.service';
import { QuickConnectService } from '../../services/quick-connect/quick-connect.service';
import { BookingStep, BookingStepService } from '../../services/booking-step/booking-step.service';
import { ProvinceBookingComponent } from '../province-booking/province-booking.component';
import { BillingTypeBookingComponent } from '../billing-type-booking/billing-type-booking.component';
import { BillingTypeService } from '../../services/billing-type/billing-type.service';
import { BillingRegionService } from '../../services/billing-region/billing-region.service';

@Component({
  selector: 'insig-booking-quick-connect',
  templateUrl: './quick-connect.component.html',
  styleUrls: ['./quick-connect.component.scss'],
})
export class QuickConnectComponent implements OnInit {
  private quickConnectService = inject(QuickConnectService);
  public doctorService = inject(DoctorService);
  private route = inject(ActivatedRoute);
  private bookingStepService = inject(BookingStepService);
  private billingTypeService = inject(BillingTypeService);
  private billingRegionService = inject(BillingRegionService);
  public AppointmentServiceMedium = AppointmentServiceMedium;

  public quickConnectDoctor: DoctorSearchData | undefined;
  public document = document;

  private companyId: string;

  public isContinueDisabled = false;

  constructor(
  ) {
    const companyBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(this.route, [CompanyBookingComponent]);
    this.companyId = companyBookingRoute?.snapshot.params.companyId;
    if (this.companyId === undefined) {
      const provinceBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(this.route, [ProvinceBookingComponent]);
      const provinceAbbreviation = provinceBookingRoute.snapshot.params.provinceAbbreviation;
      const billingTypeBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(this.route, [BillingTypeBookingComponent]);
      const billingType = this.billingTypeService.parseBillingType(billingTypeBookingRoute.snapshot.params.billingType);
      const region = this.billingRegionService.getBillingRegion(provinceAbbreviation, billingType);

      this.bookingStepService.jumpToStep(BookingStep.CHOOSE_DOCTOR, {
        navigationExtras: { relativeTo: companyBookingRoute },
        pathParams: { region },
      });
    }
  }

  async ngOnInit(): Promise<void> {
    this.quickConnectDoctor = await this.quickConnectService.getQuickConnectDoctor(this.companyId);
  }

  async handleShufflePractitionerClicked(): Promise<void> {
    this.quickConnectDoctor = await this.quickConnectService.getQuickConnectDoctor(this.companyId);
  }

  handleBackClicked(): void {
    history.back();
  }

  async handleContinueClicked(): Promise<void> {
    if (!this.isContinueDisabled) {
      this.isContinueDisabled = true;
      try {
        const draftAppointment = await this.quickConnectService.bookQuickConnectAppointment();
        const { appointmentDraftId } = draftAppointment;

        if (appointmentDraftId !== undefined) {
          const companyBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(this.route, [CompanyBookingComponent]);
          this.bookingStepService.jumpToStep(BookingStep.LOGIN, {
            navigationExtras: { relativeTo: companyBookingRoute },
            pathParams: { draftAppointmentId: appointmentDraftId },
          });
        }
      } catch (error) {
        console.error(error);
        this.isContinueDisabled = false;
        throw error;
      }
    }
  }
}
