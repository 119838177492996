import { Component, OnDestroy, inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SNACK_BAR_AUTO_DISMISS_MILLISECONDS } from '@insig-health/config/config';
import { FormValidatorsService } from '@insig-health/services/form-validators/form-validators.service';

import { PatientProfile, PatientProfileService } from '@insig-health/services/patient-profile/patient-profile.service';

export enum PharmacyStandAloneFormState {
  SHOW_PHARMACY_INFO,
  EDIT_PHARMACY_INFO,
}

type PharmacyInfoFormGroup = FormGroup<{
  pharmacyName: FormControl<string | null>;
  pharmacyFaxNumber: FormControl<string | null>;
}>;

@Component({
  selector: 'insig-booking-pharmacy-stand-alone-form',
  templateUrl: './pharmacy-stand-alone-form.component.html',
})
export class PharmacyStandAloneFormComponent implements OnDestroy {
  public static readonly PHARMACY_FORM_NAME = 'pharmacyInfoStandAloneForm';
  public static readonly PHARMACY_INFO_UPDATED_MESSAGE = 'Pharmacy information updated';
  public static readonly PHARMACY_INFO_REQUIRED_FIELDS_MESSAGE = 'Please fill in all required fields correctly';

  private formBuilder = inject(FormBuilder);
  private formValidatorsService = inject(FormValidatorsService);
  private patientProfileService = inject(PatientProfileService);
  private snackBar = inject(MatSnackBar);

  public PharmacyStandAloneFormState = PharmacyStandAloneFormState;

  public pharmacyStandAloneFormState = PharmacyStandAloneFormState.SHOW_PHARMACY_INFO;

  public readonly PHARMACY_NAME_FORM_CONTROL_NAME = 'pharmacyName';
  public readonly PHARMACY_FAX_NUMBER_FORM_CONTROL_NAME = 'pharmacyFaxNumber';

  public pharmacyStandAloneForm = this.formBuilder.group({
    [this.PHARMACY_NAME_FORM_CONTROL_NAME]: ['', [Validators.required]],
    [this.PHARMACY_FAX_NUMBER_FORM_CONTROL_NAME]: ['', [Validators.required, this.formValidatorsService.isPhoneNumberValidValidator(false)]],
  });

  public patientProfileSubscription = this.patientProfileService.getCurrentUserPatientProfile().subscribe((patientProfile) => {
    if (patientProfile) {
      this.populatePharmacyFormFromPatientProfile(this.pharmacyStandAloneForm, patientProfile);
    }
  });

  ngOnDestroy(): void {
    this.patientProfileSubscription.unsubscribe();
  }

  populatePharmacyFormFromPatientProfile(form: PharmacyInfoFormGroup, patientProfile: PatientProfile): void {
    form.patchValue({
      [this.PHARMACY_NAME_FORM_CONTROL_NAME]: patientProfile.pharmacyName,
      [this.PHARMACY_FAX_NUMBER_FORM_CONTROL_NAME]: patientProfile.pharmacyFax,
    });
  }

  async updatePharmacy(form: FormGroup): Promise<void> {
    if (form.valid) {
      await this.patientProfileService.setCurrentUserPatientProfile({
        pharmacyName: form.value[this.PHARMACY_NAME_FORM_CONTROL_NAME],
        pharmacyFax: form.value[this.PHARMACY_FAX_NUMBER_FORM_CONTROL_NAME],
      });

      this.snackBar.open(PharmacyStandAloneFormComponent.PHARMACY_INFO_UPDATED_MESSAGE, undefined, { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS });

      this.pharmacyStandAloneFormState = PharmacyStandAloneFormState.SHOW_PHARMACY_INFO;
    } else {
      this.snackBar.open(PharmacyStandAloneFormComponent.PHARMACY_INFO_REQUIRED_FIELDS_MESSAGE, undefined, { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS });
    }
  }

  handleEditButtonClicked(): void {
    this.pharmacyStandAloneFormState = PharmacyStandAloneFormState.EDIT_PHARMACY_INFO;
  }
}
