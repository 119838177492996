<insig-ui-popup-menu [(visibleState)]="popupVisibleState">
  <button popupButton role="button">
    <img class="pe-1 changeLanguageIcon" src="assets/img/ico-lang.svg" alt="Change Language"/>
    <span *ngFor="let language of languages"
      class="bold font-size-sm currentLanguageLabel"
      [ngClass]="{ 'd-none': currentLanguage !== language }"
    >
      {{ language }}
    </span>
  </button>
  <insig-ui-header-dropdown popupContent>
    <h2>Select a Language</h2>
    <ul>
      <li *ngFor="let language of languages">
        <insig-ui-radio-input
          radioGroup="languages" 
          [label]="language" 
          [value]="language"
          [isChecked]="currentLanguage === language"
          (change)="handleLanguageClicked($event)"
        >
        </insig-ui-radio-input>
      </li>
    </ul>
  </insig-ui-header-dropdown>
</insig-ui-popup-menu>
