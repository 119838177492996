import { UntypedFormBuilder, UntypedFormGroup, FormGroup } from '@angular/forms';
import { Component, ViewChild, inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { DateAndTimeService } from '@insig-health/services/date-and-time/date-and-time.service';

import { PatientProfileFormComponent } from '../patient-profile-form/patient-profile-form.component';

import { FamilyMemberProfile, FamilyMemberService } from '@insig-health/services/family-member/family-member.service';
import { SNACK_BAR_AUTO_DISMISS_MILLISECONDS } from '@insig-health/config/angular-material.config';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'insig-booking-edit-family-member-form-dialog',
  templateUrl: './edit-family-member-form-dialog.component.html',
})
export class EditFamilyMemberFormDialogComponent {
  public static readonly DIALOG_MAX_WIDTH = '796px';
  public static readonly DIALOG_WIDTH = '100vw';
  public static readonly SUCCESS_SNACKBAR_STRING = 'Patient was updated successfully';
  public static readonly DEFAULT_ERROR_SNACKBAR_STRING = 'An error occurred while updating the patient';

  private dateAndTimeService = inject(DateAndTimeService);
  private dialogRef = inject<MatDialogRef<EditFamilyMemberFormDialogComponent>>(MatDialogRef<EditFamilyMemberFormDialogComponent>);
  private formBuilder = inject(UntypedFormBuilder);
  private familyMemberService = inject(FamilyMemberService);
  private snackBar = inject(MatSnackBar);
  private data = inject<{ familyMemberProfile: FamilyMemberProfile }>(MAT_DIALOG_DATA);

  public PATIENT_PROFILE_FORM_NAME = PatientProfileFormComponent.PATIENT_PROFILE_FORM_NAME;

  @ViewChild('patientProfileForm') patientProfileForm!: PatientProfileFormComponent;

  public familyMemberProfile = this.data.familyMemberProfile;

  public readonly editPatientForm = this.formBuilder.group({});

  async handleSaveButtonClicked(form: UntypedFormGroup): Promise<void> {
    if (form.invalid) {
      return;
    }

    try {
      await this.saveFamilyMemberProfileInfo(form);
      this.dialogRef.close(true);
      this.snackBar.open(
        EditFamilyMemberFormDialogComponent.SUCCESS_SNACKBAR_STRING,
        undefined,
        { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS },
      );
    } catch (error) {
      console.error(error);
      this.snackBar.open(
        (error as HttpErrorResponse).error?.errorMessage ?? EditFamilyMemberFormDialogComponent.DEFAULT_ERROR_SNACKBAR_STRING,
        undefined,
        { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS },
      );
    }
  }

  async saveFamilyMemberProfileInfo(form: UntypedFormGroup): Promise<void> {
    const familyMemberProfile = {
      ...await this.familyMemberService.getFamilyMemberProfile(this.familyMemberProfile.patientId, this.familyMemberProfile.familyMemberId),
      ...this.getFamilyMemberProfileFromForm(form),
    };
    await this.familyMemberService.setFamilyMember(this.familyMemberProfile.patientId, this.familyMemberProfile.familyMemberId, familyMemberProfile);
  }

  getFamilyMemberProfileFromForm(form: UntypedFormGroup): FamilyMemberProfile {
    const patientProfileForm = form.controls[this.PATIENT_PROFILE_FORM_NAME] as FormGroup;
    const birthdate = patientProfileForm.controls[this.patientProfileForm.DATE_OF_BIRTH_FORM_CONTROL_NAME].value;
    return {
      firstName: patientProfileForm.controls[this.patientProfileForm.FIRST_NAME_FORM_CONTROL_NAME].value,
      lastName: patientProfileForm.controls[this.patientProfileForm.LAST_NAME_FORM_CONTROL_NAME].value,
      day: this.dateAndTimeService.getDayAsNumberFromIsoString(birthdate),
      month: this.dateAndTimeService.getMonthAsNumberFromIsoString(birthdate),
      year: this.dateAndTimeService.getYearAsNumberFromIsoString(birthdate),
      gender: patientProfileForm.controls[this.patientProfileForm.GENDER_FORM_CONTROL_NAME].value,
      address: patientProfileForm.controls[this.patientProfileForm.STREET_ADDRESS_FORM_CONTROL_NAME].value,
      city: patientProfileForm.controls[this.patientProfileForm.CITY_FORM_CONTROL_NAME].value,
      province: patientProfileForm.controls[this.patientProfileForm.PROVINCE_FORM_CONTROL_NAME].value,
      postalCode: patientProfileForm.controls[this.patientProfileForm.POSTAL_CODE_FORM_CONTROL_NAME].value,
      healthCardNumber: patientProfileForm.controls[this.patientProfileForm.HEALTH_CARD_NUMBER_FORM_CONTROL_NAME].value,
      phone: patientProfileForm.controls[this.patientProfileForm.PHONE_NUMBER_FORM_CONTROL_NAME].value,
      familyDoctorFullName: patientProfileForm.controls[this.patientProfileForm.FAMILY_DOCTOR_FULL_NAME_FORM_CONTROL_NAME].value,
      familyDoctorFaxNumber: patientProfileForm.controls[this.patientProfileForm.FAMILY_DOCTOR_FAX_NUMBER_FORM_CONTROL_NAME].value,
    } as FamilyMemberProfile;
  }
}
