import { AfterViewChecked, ChangeDetectionStrategy, Component, ElementRef, HostListener, Input, OnChanges, SimpleChanges, inject } from '@angular/core';
import { ExtendedSlotData } from '@insig-health/services/doctor/doctor.service';
import { DateAndTimeService } from '@insig-health/services/date-and-time/date-and-time.service';
import { DocumentService } from '../../../../services/document/document.service';
import { SelectedTimeSlotService } from '../../../../services/selected-time-slot/selected-time-slot.service';

const LABEL_REGEX = /^(?<startHour>\d+) - (?<endHour>\d+) (?<amOrPm>(AM|PM))$/;
const OTHER_HOURLY_SLOT_CLICKED_EVENT_NAME = 'otherHourlySlotClicked';

@Component({
  selector: 'insig-booking-hourly-slot',
  templateUrl: './hourly-slot.component.html',
  styleUrls: ['./hourly-slot.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HourlySlotComponent implements AfterViewChecked, OnChanges {
  private dateAndTimeService = inject(DateAndTimeService);
  private selectedTimeSlotService = inject(SelectedTimeSlotService);
  private elementRef = inject(ElementRef);
  private documentService = inject(DocumentService);
  @Input() timeSlotData: ExtendedSlotData[] = [];
  @Input() label = '';
  @Input() currentWeekStartDate: string | undefined;

  public expanded = false;
  public prevExpanded = false;
  public truncatedLabel = '';

  private _otherHourlySlotComponents: Element[] = [];

  @HostListener(OTHER_HOURLY_SLOT_CLICKED_EVENT_NAME) handleOtherHourlySlotClicked(): void {
    this.expanded = false;
  }

  ngAfterViewChecked(): void {
    const tagName = (this.elementRef.nativeElement as HTMLElement).tagName.toLowerCase();
    const allHourlySlots = Array.from(this.documentService.getAllElementsByQuery(tagName));
    const otherHourlySlots = allHourlySlots.filter((element) => element !== this.elementRef.nativeElement);
    this._otherHourlySlotComponents = otherHourlySlots;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const label = changes.label?.currentValue as string;
    if (label && LABEL_REGEX.test(label)) {
      this.truncatedLabel = this.getTruncatedLabel(label);
    }

    if (this.currentWeekChanged(changes)) {
      this.expanded = false;
    }
  }

  handleTimeSlotContainerClick(): void {
    this._otherHourlySlotComponents.forEach((otherHourlySlotComponent) => {
      otherHourlySlotComponent.dispatchEvent(new Event(OTHER_HOURLY_SLOT_CLICKED_EVENT_NAME));
    });
    this.expanded = true;
  }

  handleCloseButtonClick(): void {
    this.expanded = false;
  }

  getAppointmentSlotStartTime(slotData: ExtendedSlotData): string {
    const timezone = this.dateAndTimeService.getLocalTimeZone();

    return this.dateAndTimeService.getHourAndMinute(slotData.startDate, timezone);
  }

  handleTimeSlotOptionClick(timeSlotData: ExtendedSlotData): void {
    this.selectedTimeSlotService.setSelectedTimeSlot(timeSlotData);
  }

  getTruncatedLabel(label: string): string {
    const matches = LABEL_REGEX.exec(label);
    if (matches?.groups) {
      const { startHour, endHour, amOrPm } = matches.groups;

      const isEndHourAm = amOrPm === 'AM';
      const isEndHourTwelve = endHour === '12';
      const isStartHourAm = isEndHourTwelve ? !isEndHourAm : isEndHourAm;

      return `${startHour}\n${isStartHourAm ? 'AM' : 'PM'}`;
    } else {
      throw new Error('Malformed label string');
    }
  }

  currentWeekChanged(changes: SimpleChanges): boolean {
    return changes.currentWeekStartDate?.currentValue !== changes.currentWeekStartDate?.previousValue;
  }
}
