import { Component, ElementRef, EventEmitter, HostListener, Input, Output, inject } from '@angular/core';

export enum InsigPopupMenuVisibleState {
  OPEN,
  CLOSED,
}

@Component({
  selector: 'insig-ui-popup-menu',
  templateUrl: './insig-popup-menu.component.html',
  styleUrls: ['./insig-popup-menu.component.scss'],
})
export class InsigPopupMenuComponent {
      private elementRef = inject(ElementRef);
  public InsigPopupMenuVisibleState = InsigPopupMenuVisibleState;
  @Input() public visibleState = InsigPopupMenuVisibleState.CLOSED;
  @Output() public visibleStateChange = new EventEmitter<InsigPopupMenuVisibleState>();

  @HostListener('document:click', ['$event']) handleHostClick(event: MouseEvent): void {
    const isClickInside = this.elementRef.nativeElement.contains(event.target);

    if (!isClickInside) {
      this.visibleState = InsigPopupMenuVisibleState.CLOSED;
      this.visibleStateChange.emit(this.visibleState);
    }
  }

  handlePopupButtonClicked(): void {
    if (this.visibleState === InsigPopupMenuVisibleState.OPEN) {
      this.visibleState = InsigPopupMenuVisibleState.CLOSED;
      this.visibleStateChange.emit(this.visibleState);
    } else {
      this.visibleState = InsigPopupMenuVisibleState.OPEN;
      this.visibleStateChange.emit(this.visibleState);
    }
  }
}
