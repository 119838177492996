import { Component, inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'insig-booking-error-dialog',
  templateUrl: './error-dialog.component.html',
})
export class ErrorDialogComponent {
  public data = inject<{ error?: Error }>(MAT_DIALOG_DATA);
  public dialogRef = inject<MatDialogRef<ErrorDialogComponent>>(MatDialogRef<ErrorDialogComponent>);
  public readonly GENERIC_ERROR_MESSAGE = 'Please refresh your page or try again later.';
}
