import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CompanyBookingComponent } from '../../company-booking/company-booking.component';
import { AppointmentReservationService, DraftAppointmentSlot } from 'apps/insig-booking/src/services/appointment-reservation/appointment-reservation.service';
import { Subscription, firstValueFrom } from 'rxjs';
import { filter } from 'rxjs/operators';
import { BookingStep, BookingStepService } from '../../../services/booking-step/booking-step.service';
import { ProvinceBookingComponent } from '../../province-booking/province-booking.component';
import { BillingTypeService } from 'apps/insig-booking/src/services/billing-type/billing-type.service';
import { BillingTypeBookingComponent } from '../../billing-type-booking/billing-type-booking.component';
import { BillingRegionService } from 'apps/insig-booking/src/services/billing-region/billing-region.service';

@Component({
  selector: 'insig-booking-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent implements OnInit, OnDestroy {
  private activatedRoute = inject(ActivatedRoute);
  private appointmentReservationService = inject(AppointmentReservationService);
  private bookingStepService = inject(BookingStepService);
  private billingRegionService = inject(BillingRegionService);
  private billingTypeService = inject(BillingTypeService);
  public currentBookingStep: BookingStep | undefined;
  public BookingStep = BookingStep;

  private bookingStepChangedSubscription: Subscription | undefined;

  ngOnInit(): void {
    this.bookingStepChangedSubscription = this.bookingStepService.bookingStepChange$.subscribe((bookingStep) => {
      this.currentBookingStep = bookingStep;
    });
  }

  ngOnDestroy(): void {
    this.bookingStepChangedSubscription?.unsubscribe();
  }

  isChooseDoctorStepComplete(currentBookingStep: BookingStep | undefined): boolean {
    return (
      currentBookingStep !== BookingStep.CHOOSE_DOCTOR &&
      currentBookingStep !== BookingStep.QUICK_CONNECT &&
      currentBookingStep !== undefined
    );
  }

  isChooseTimeStepComplete(currentBookingStep: BookingStep | undefined): boolean {
    return (
      currentBookingStep === BookingStep.LOGIN ||
      currentBookingStep === BookingStep.CONFIRM_BOOKING ||
      currentBookingStep === BookingStep.SUCCESS
    );
  }

  isLoginStepComplete(currentBookingStep: BookingStep | undefined): boolean {
    return (
      currentBookingStep === BookingStep.CONFIRM_BOOKING ||
      currentBookingStep === BookingStep.SUCCESS
    );
  }

  isConfirmBookingStepComplete(currentBookingStep: BookingStep | undefined): boolean {
    return currentBookingStep === BookingStep.SUCCESS;
  }

  isBookingStepActive(currentBookingStep: BookingStep | undefined, bookingStepToCheck: BookingStep): boolean {
    return currentBookingStep === bookingStepToCheck;
  }

  async handleChooseDoctorStepClicked(): Promise<void> {
    const companyBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(this.activatedRoute, [CompanyBookingComponent]);
    const deepestRoute = this.bookingStepService.getDeepestActivatedRouteChild(this.activatedRoute);

    const currentReservedAppointmentSlot = await firstValueFrom(this.appointmentReservationService.getCurrentReservedAppointmentSlot());

    if (currentReservedAppointmentSlot !== undefined) {
      const billingType = this.billingTypeService.parseBillingType(currentReservedAppointmentSlot.billingType);
      const region = this.billingRegionService.getBillingRegion(currentReservedAppointmentSlot.province, billingType);

      this.bookingStepService.jumpToStep(BookingStep.CHOOSE_DOCTOR, {
        navigationExtras: { relativeTo: companyBookingRoute },
        pathParams: { region },
      });
    } else {
      const provinceBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(deepestRoute, [ProvinceBookingComponent]);
      const provinceAbbreviation = provinceBookingRoute.snapshot.params.provinceAbbreviation;
      const billingTypeBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(deepestRoute, [BillingTypeBookingComponent]);
      const billingType = this.billingTypeService.parseBillingType(billingTypeBookingRoute.snapshot.params.billingType);
      const region = this.billingRegionService.getBillingRegion(provinceAbbreviation, billingType);
      this.bookingStepService.jumpToStep(BookingStep.CHOOSE_DOCTOR, {
        navigationExtras: { relativeTo: companyBookingRoute },
        pathParams: { region },
      });
    }
  }

  async handleChooseTimeStepClicked(): Promise<void> {
    const currentReservedAppointmentSlot = await firstValueFrom(this.appointmentReservationService.getCurrentReservedAppointmentSlot().pipe(
      filter((appointmentSlot): appointmentSlot is DraftAppointmentSlot => appointmentSlot !== undefined),
    ));
    const companyBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(this.activatedRoute, [CompanyBookingComponent]);
    const region = this.billingRegionService.getBillingRegion(currentReservedAppointmentSlot.province, this.billingTypeService.parseBillingType(currentReservedAppointmentSlot.billingType));
    this.bookingStepService.jumpToStep(BookingStep.CHOOSE_TIME, {
      navigationExtras: {
        relativeTo: companyBookingRoute,
      },
      pathParams: {
        region,
        doctorId: currentReservedAppointmentSlot.doctorId,
        serviceId: currentReservedAppointmentSlot.serviceId,
      },
    });
  }

  async handleConfirmBookingStepClicked(): Promise<void> {
    const currentReservedAppointmentSlot = await firstValueFrom(this.appointmentReservationService.getCurrentReservedAppointmentSlot().pipe(
      filter((appointmentSlot): appointmentSlot is DraftAppointmentSlot => appointmentSlot !== undefined),
    ));
    const companyBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(this.activatedRoute, [CompanyBookingComponent]);
    this.bookingStepService.jumpToStep(BookingStep.CONFIRM_BOOKING, {
      navigationExtras: {
        relativeTo: companyBookingRoute,
      },
      pathParams: {
        draftAppointmentId: currentReservedAppointmentSlot.appointmentId,
      },
    });
  }
}
