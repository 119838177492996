<div role="button" class="radio" [ngClass]="{ disabled: _isDisabled }">
  <label class="d-flex flex-row-reverse justify-content-end align-items-center gap-2">
    <span class="bold textSecondary">{{ label }}</span>
    <input type="radio"
      [name]="radioGroup"
      [checked]="_isChecked"
      [disabled]="_isDisabled"
      [value]="value"
      (change)="handleChange($event)"
    >
    <span class="checkmark"></span>
  </label>
</div>
